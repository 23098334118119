
import './HomePage.css';


const HomePage = () => {
  return (
    <div className="hero-container">
      <div className="content">
        <h1>Home</h1>
        <p>Makhentsa cc  we offer a wide range of construction and renovation services, including, Plant Hire Equipment,Industrial Cleaning etc..
           making us your one-stop solution for all your construction needs. Whether you're planning a new construction project or renovating an existing property,
            our team is here to bring your vision to life.</p>

            <p>Our core values include a strong commitment to client satisfaction, the highest standards of quality, and a focus on safety.
               We take pride in our attention to detail and ensure that every project is completed to the highest industry standards.</p>
      </div>
    
    </div>
  );
};

export default HomePage;




































